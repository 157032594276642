<template>
  <nav v-if="showNavigation">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      showNavigation: false,
    };
  },
};
</script>
