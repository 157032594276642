<template>
  <section
    id="contact-section"
    class="page-section"
    :class="{
      'aquamarine--background': !isAboutSection,
    }"
  >
    <div data-aos="fade" data-aos-duration="1000" class="contact__content">
      <SanityBlocks :blocks="contactMessage" />
    </div>
  </section>
</template>

<script>
import commonMixin from '../mixins/common-mixin';

export default {
  mixins: [commonMixin],
  props: {
    contactMessage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isAboutSection: false,
    };
  },
};
</script>
