<template>
  <swiper
    class="swiper"
    ref="swiper"
    :allow-touch-move="false"
    :autoplay="{ delay: 3000, disableOnInteraction: false }"
    :loop="true"
    effect="fade"
  >
    <swiper-slide v-for="image in imageCarousel" :key="image.asset._id">
      <img :src="imageUrlFor(image.asset)" />
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';

// import Swiper core and required modules
import SwiperCore, { Autoplay, EffectFade } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade]);

export default {
  props: {
    imageCarousel: {
      type: Object,
      required: true,
    },
    imageUrlFor: {
      type: Function,
      required: true,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
