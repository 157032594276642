<template>
  <section id="about-section" class="page-section">
    <div data-aos="fade" data-aos-duration="1000" class="about__content">
      <SanityBlocks :blocks="aboutUsContent" />
    </div>
  </section>
</template>

<script>
import commonMixin from '../mixins/common-mixin';

export default {
  mixins: [commonMixin],
  props: {
    aboutUsContent: {
      type: Object,
      required: true,
    },
  },
};
</script>
