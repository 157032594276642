<template>
  <div class="wrapper">
    <section class="mega-hero">
      <div class="mega-hero__inner">
        <div id="header" class="mega-hero__headerLogo">
          <img
            v-if="posts.siteSettings && posts.siteSettings.logo"
            :src="imageUrlFor(posts.siteSettings.logo)"
          />
        </div>
        <div class="mega-hero__blocks" v-if="posts && posts.imageCarousel">
          <BaseSwiper
            :imageCarousel="posts.imageCarousel.imagesGallery"
            :imageUrlFor="imageUrlFor"
          />
        </div>
        <div class="downArrow">
          <img src="@/assets/images/downArrow.svg" alt="Down Arrow" />
        </div>
      </div>
    </section>
    <AboutUsSection :aboutUsContent="aboutUsContent" />
    <ContactSection :contactMessage="contactMessage" />
  </div>
</template>

<script>
import global from '@/components/mixins/global.js';
import BaseSwiper from '@/components/third-party/BaseSwiper.vue';
import AboutUsSection from '@/components/pages/AboutUsSection.vue';
import ContactSection from '@/components/forms/ContactSection.vue';

export default {
  name: 'HomeView',
  mixins: [global],
  components: {
    BaseSwiper,
    AboutUsSection,
    ContactSection,
  },
  methods: {
    onScroll() {
      const header = document.getElementById('header');
      const isScrolled =
        document.body.scrollTop > 10 || document.documentElement.scrollTop > 10;
      header.classList.toggle('minimised', isScrolled);
    },
    onDownArrowClick() {
      const contactSection = document.querySelector('#contact-section');
      contactSection.scrollIntoView({ behavior: 'smooth' });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    const downArrow = document.querySelector('.downArrow');
    downArrow.addEventListener('click', this.onDownArrowClick);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    const downArrow = document.querySelector('.downArrow');
    downArrow.removeEventListener('click', this.onDownArrowClick);
  },
};
</script>
